import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'callback',
  pure: false,
})
export class CallbackPipe implements PipeTransform {
  transform<T>(items: T[], callback: (item: any) => boolean) {
    if (!items || !callback) {
      return items;
    }
    return items.filter((item) => callback(item));
  }
}
