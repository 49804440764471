import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AreaEquipmentFilterPipe } from './area-equipment-filter.pipe';
import { CallbackPipe } from './callback.pipe';
import { DateExtendedPipe } from './date-extended.pipe';
import { FilterPipe } from './filter.pipe';
import { GroupByPipe } from './group-by.pipe';
import { HourMinutePipe } from './hour-minute.pipe';
import { RemoveUnderscorePipe } from './remove-underscore.pipe';
import { SafePipe } from './safe.pipe';
import { SplitBcGroupPipe } from './split-bc-group.pipe';
import { TruncatePipe } from './truncate.pipe';
import { TranslocoDatePipe } from '@ngneat/transloco-locale';

@NgModule({
  declarations: [
    GroupByPipe,
    SafePipe,
    TruncatePipe,
    DateExtendedPipe,
    SplitBcGroupPipe,
    HourMinutePipe,
    CallbackPipe,
    RemoveUnderscorePipe,
    FilterPipe,
    AreaEquipmentFilterPipe,
  ],
  imports: [CommonModule, TranslocoDatePipe],
  exports: [
    GroupByPipe,
    SafePipe,
    TruncatePipe,
    DateExtendedPipe,
    SplitBcGroupPipe,
    HourMinutePipe,
    CallbackPipe,
    RemoveUnderscorePipe,
    FilterPipe,
    AreaEquipmentFilterPipe,
  ],
})
export class MainPipeModule {}
