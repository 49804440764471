import { Pipe, PipeTransform } from '@angular/core';
import type { Equipment } from '@tag/graphql';

@Pipe({
  name: 'areaEquipmentFilter',
})
export class AreaEquipmentFilterPipe implements PipeTransform {
  transform(eqs: Equipment[], code: string): Equipment[] {
    return eqs.filter((eq) => eq.area === code);
  }
}
