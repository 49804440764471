import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hourMinute',
})
export class HourMinutePipe implements PipeTransform {
  transform(value: number | string): string | undefined | null {
    if (typeof value === 'string') return value;
    if (value) {
      const hours = Math.floor(value);
      const mins = ((value % 1) * 60).toFixed();
      return String(hours).padStart(2, '0') + ':' + String(mins).padStart(2, '0');
    } else return '00:00';
  }
}
