import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string | undefined, limit = 25, completeWords = false, ellipsis = '...'): string | undefined {
    if (value) {
      if (completeWords) {
        limit = value.substr(0, limit).lastIndexOf(' ');
      }
      return value.length > limit ? value.substr(0, limit) + ellipsis : value;
    } else return undefined;
  }
}
