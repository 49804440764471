import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({ name: 'searchFilter' })
export class FilterPipe implements PipeTransform {
  constructor(private transloco: TranslocoService) {}
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter((it) =>
      this.transloco
        .translate(it + 'Key')
        .toLocaleLowerCase()
        .includes(searchText)
    );
  }
}
